import React from 'react';
import { Parallax } from 'react-scroll-parallax';

export function FlowerA({ isHidden }) {
  if (isHidden) return null;

  return (
    <Parallax y={[12, -12]}>
      <img
        alt="flower"
        className="flower-a"
        src="https://s3.amazonaws.com/papertini/home_screen/misc_flowers/FLOWER+A.png"
      />
    </Parallax>
  );
}

export function FlowerB({ isHidden }) {
  if (isHidden) return null;

  return (
    <img
      alt="flower"
      className="flower-b child"
      src="https://s3.amazonaws.com/papertini/home_screen/misc_flowers/FLOWER+B.png"
    />
  );
}

export function FlowerC({ isHidden }) {
  if (isHidden) return null;

  return (
    <img
      alt="flower"
      className="flower-c"
      src="https://s3.amazonaws.com/papertini/home_screen/misc_flowers/FLOWER+C.png"
    />
  );
}

export function FlowerD({ isHidden }) {
  if (isHidden) return null;

  return (
    <img
      alt="flower"
      className="flower-d"
      src="https://s3.amazonaws.com/papertini/home_screen/misc_flowers/FLOWER+D.png"
    />
  );
}

export function Balloons() {
  return (
    <div className="balloons">
      <img
        alt="baloons"
        src="https://s3.amazonaws.com/papertini/home_screen/03_tiles/BALLOON.png"
      />
    </div>
  );
}

export function Cross() {
  return (
    <div className="row cross">
      <img
        alt="decorative cross"
        src="https://s3.amazonaws.com/papertini/home_screen/misc_flowers/star-02.svg"
      />
    </div>
  );
}
