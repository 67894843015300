import { push } from 'connected-react-router';
import {
  CREATE_USER,
  EDIT_USER,
  DELETE_USER,
  ADD_USER,
  OPEN_USER_MODAL,
  CLOSE_USER_MODAL,
  SET_ALL_USERS,
} from './types';
import axios from 'axios';

export const openUserModal = () => (dispatch, getState) => {
  dispatch({ type: OPEN_USER_MODAL });
};

export const closeUserModal = () => (dispatch, getState) => {
  dispatch({ type: CLOSE_USER_MODAL });
};

export const createUser = values => (dispatch, getState) => {
  dispatch({ type: CREATE_USER.PENDING });
  const { photo, ...data } = values;
  const formattedName = data.name.replace(/[^a-zA-Z0-9]/g, '');
  const fileID = Date.now().toString();

  fetch(`${process.env.REACT_APP_API_URL}/api/s3/sign`, {
    method: 'POST',
    body: JSON.stringify({
      filePath: `user photos/${formattedName}/${fileID}`,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json())
    .then(body => {
      data.photoUrl = body.publicUrl;

      return fetch(body.signedUrl, {
        method: 'PUT',
        body: photo.blob,
        headers: {
          'Content-Type': 'image/jpeg',
        },
      });
    })
    .then(() =>
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/users/register`,
        data,
        headers: {
          Authorization: `${getState().auth.token}`,
        },
      })
    )
    .then(response => {
      dispatch({ type: CREATE_USER.SUCCESS, payload: response });
      return response;
    })
    .then(response => dispatch({ type: ADD_USER, payload: response.data }))
    .then(() => dispatch(push('/employee/edit-users'))) //go to index page of all users
    .catch(error => {
      dispatch({
        type: CREATE_USER.FAIL,
        payload: error.response.data.errors,
      });
    });
};

export const editUser = values => async (dispatch, getState) => {
  dispatch({ type: EDIT_USER.PENDING });
  const { photo, ...data } = values;
  try {
    if (photo.blob) {
      const formattedName = data.name.replace(/[^a-zA-Z0-9]/g, '');
      const fileID = Date.now().toString();

      const { publicUrl, signedUrl } = await fetch(
        `${process.env.REACT_APP_API_URL}/api/s3/sign`,
        {
          method: 'POST',
          body: JSON.stringify({
            filePath: `user photos/${formattedName}/${fileID}`,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ).then(res => res.json());

      await fetch(signedUrl, {
        method: 'PUT',
        body: photo.blob,
        headers: {
          'Content-Type': 'image/jpeg',
        },
      });

      values.photoUrl = publicUrl;
    }

    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/api/users`,
      data: values,
      headers: {
        Authorization: `${getState().auth.token}`,
      },
    });
    dispatch({ type: EDIT_USER.SUCCESS, payload: response.data });
    dispatch({ type: SET_ALL_USERS, payload: response.data });
    dispatch({ type: CLOSE_USER_MODAL });
    dispatch(push('/employee')); //go to index page of all users
  } catch (e) {
    console.log('EDIT USER ERROR', e);
    dispatch({ type: EDIT_USER.FAIL, payload: e });
  }
};

export const deleteUser = values => (dispatch, getState) => {
  dispatch({ type: DELETE_USER.PENDING });

  axios({
    method: 'delete',
    url: `${process.env.REACT_APP_API_URL}/api/users`,
    data: values,
    headers: {
      Authorization: `${getState().auth.token}`,
    },
  })
    .then(response => {
      dispatch({ type: DELETE_USER.SUCCESS, payload: response.data });
      dispatch({ type: SET_ALL_USERS, payload: response.data });
      dispatch({ type: CLOSE_USER_MODAL });
    })
    .then(() => dispatch(push('/employee'))) //go to index page of all users
    .catch(error => {
      dispatch({ type: DELETE_USER.FAIL, payload: error.response.data.errors });
    });
};
