import React from 'react';
import { connect } from 'react-redux';
import { NavLink, Route } from 'react-router-dom';

import EventForm from '../event-form';
import CreateUser from '../create-user';
import EditUsers from '../edit-users';
import SpecialForm from '../special-form';

const path = {
  createUser: '/employee/create-user',
  editUsers: '/employee/edit-users',
  eventForm: '/employee/event-form',
  special: '/employee/special',
};

function Portal({ currentUser }) {
  return (
    <div className="employee-portal">
      <h3 className="gothic">EMPLOYEE MENU</h3>
      <NavLink className="event-form-link" to={path.eventForm}>
        Create new event
      </NavLink>
      <br />
      {currentUser.isAdmin && (
        <React.Fragment>
          <NavLink className="event-form-link" to={path.createUser}>
            Create new user
          </NavLink>
          <br />
          <NavLink className="event-form-link" to={path.editUsers}>
            Edit users
          </NavLink>
          <br />
        </React.Fragment>
      )}
      <NavLink className="event-form-link" to={path.special}>
        Edit Special
      </NavLink>
      <Route path={path.eventForm} render={props => <EventForm {...props} />} />
      <Route
        path={path.createUser}
        render={props => <CreateUser {...props} />}
      />
      <Route path={path.editUsers} render={props => <EditUsers {...props} />} />
      <Route path={path.special} render={props => <SpecialForm {...props} />} />
    </div>
  );
}

const mapDispatchToProps = {
  setUser: () => {},
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Portal);
