import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { Link } from 'react-router-dom';

class NewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      password2: '',
      message: '',
      loading: false,
    };
  }

  //refactor using redux/thunk
  changePassword(token, values) {
    this.setState({ loading: true });

    fetch(`${process.env.REACT_APP_API_URL}/api/users/reset/${token}`, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(body => {
        if (body.error) {
          this.setState({ message: body.error, loading: false });
        } else if (body.success) {
          this.setState({
            message: 'Success! Password has been changed!',
            loading: false,
          });
        }
      })
      .catch(body => {
        this.setState({
          message: body.error || 'There was an error, please try again',
        });
      });
  }

  onSubmit = event => {
    event.preventDefault();
    const { password, password2 } = this.state;
    const { token } = this.props.match.params;

    this.changePassword(token, { password, password2 });
  };

  renderButton() {
    if (this.state.loading) {
      return <p>L o a d i n g . . .</p>;
    }

    return (
      <button
        className="btn"
        style={{ backgroundColor: '#e9aba3' }}
        type="submit"
      >
        Submit
      </button>
    );
  }

  render() {
    const { password, password2, message } = this.state;

    return (
      <div className="login-form gothic">
        <h2>Reset Password</h2>
        <form className="box" onSubmit={this.onSubmit}>
          <div>
            <input
              id="password"
              className="login-password"
              type="password"
              placeholder="New Password"
              onChange={e => this.setState({ password: e.target.value })}
              value={password}
            />
          </div>

          <div>
            <input
              id="password2"
              className="login-password"
              type="password"
              placeholder="Renter Password"
              onChange={e => this.setState({ password2: e.target.value })}
              value={password2}
            />
          </div>

          <div>{message}</div>

          <div>{this.renderButton()}</div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { email, password, error, loading, forgotPassword } = auth;

  return { email, password, error, loading, forgotPassword };
};

export default connect(
  mapStateToProps,
  actions
)(NewPassword);

NewPassword.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  emailChanged: PropTypes.func.isRequired,
  passwordChanged: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
};
