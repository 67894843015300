async function getPhotoUrls(photo, filePath) {
  return fetch(`${process.env.REACT_APP_API_URL}/api/s3/sign`, {
    method: 'POST',
    body: JSON.stringify({
      filePath: `${filePath}/${photo.name}`,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json())
    .then(({ publicUrl, signedUrl }) => ({ publicUrl, signedUrl }))
    .catch(e => console.error('service error: getPhotoUrls', e));
}

async function updoadPhoto(photo, signedUrl) {
  return fetch(signedUrl, {
    method: 'PUT',
    body: photo.blob,
    headers: {
      'Content-Type': 'image/jpeg',
    },
  })
    .then(res => (res.ok ? res : Promise.reject(res)))
    .catch(e => {
      console.error('service error: uploadPhoto', e);
      return Promise.reject(e);
    });
}

export default async function uploadPhotos(photos, filePath) {
  return Promise.all(
    photos.map(async (photo, i) => {
      const file = new File([photo.blob], `${photo.name}.jpeg`, {
        type: 'image/jpeg',
      });

      try {
        const { publicUrl, signedUrl } = await getPhotoUrls(photo, filePath);
        await updoadPhoto(photo, signedUrl);
        return publicUrl;
      } catch (e) {
        console.error('service error: uploadPhotos', e);
        return Promise.reject(e);
      }
    })
  );
}
