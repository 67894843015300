import { SET_INSTAGRAM_DATA, LOAD_INSTAGRAM_DATA } from '../actions/types';

const INITIAL_STATE = {
  response: [],
  error: '',
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_INSTAGRAM_DATA:
      return { ...state, loading: true };
    case SET_INSTAGRAM_DATA:
      return { ...state, response: action.payload, loading: false };

    default:
      return state;
  }
};
