import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { Link } from 'react-router-dom';
import reducers from '../../../reducers';
import PhotoSection from './photo-section';

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      title: '',
      email: '',
      password: '',
      password2: '',
      canCreate: false,
      canEdit: false,
      canDelete: false,
      description: '',
      photo: '',
    };
  }

  generatePermissionsArray = () => {
    const { canCreate, canEdit, canDelete } = this.state;

    let permissions = [];
    if (canCreate) {
      permissions.push('CREATE');
    }
    if (canEdit) {
      permissions.push('UPDATE');
    }
    if (canDelete) {
      permissions.push('DELETE');
    }
    return permissions;
  };

  onSubmit = e => {
    e.preventDefault();
    const {
      name,
      email,
      password,
      password2,
      description,
      title,
      photo,
    } = this.state;
    const { createUser } = this.props;
    const permissions = this.generatePermissionsArray();

    createUser({
      name,
      title,
      email,
      password,
      password2,
      description,
      permissions,
      photo,
    });
  };

  onTextChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheck = e => {
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  };

  renderButton = () => {
    if (this.props.loading) {
      return <p>L o a d i n g . . .</p>;
    }

    return (
      <button className="btn" type="submit">
        Create User
      </button>
    );
  };

  renderErrors = () => {
    return Object.values(this.props.errors).map(error => {
      return (
        <div className="error" style={{ color: 'red' }}>
          {error}
        </div>
      );
    });
  };

  render() {
    const {
      name,
      title,
      email,
      password,
      password2,
      description,
      canCreate,
      canEdit,
      canDelete,
      photo,
    } = this.state;

    const { loading, errors } = this.props;

    return (
      <div style={{ width: '100%' }} className="login-form create-user">
        <h2>Create User</h2>
        <form className="box" onSubmit={this.onSubmit}>
          <input
            id="name"
            name="name"
            className="login-email"
            type="text"
            placeholder="Name"
            onChange={e => this.onTextChange(e)}
            value={name}
          />
          <input
            id="title"
            name="title"
            className="login-email"
            type="text"
            placeholder="Title"
            onChange={e => this.onTextChange(e)}
            value={title}
          />
          <input
            id="email"
            name="email"
            className="login-email"
            type="text"
            placeholder="Email"
            onChange={e => this.onTextChange(e)}
            value={email}
          />
          <input
            id="password"
            name="password"
            className="login-password"
            type="password"
            placeholder="Password"
            onChange={e => this.onTextChange(e)}
            value={password}
          />
          <input
            id="password2"
            name="password2"
            className="login-password"
            type="password"
            placeholder="Confirm Password"
            onChange={e => this.onTextChange(e)}
            value={password2}
          />
          <label>
            Employee Description:
            <textarea
              id="description"
              name="description"
              className="login-email"
              type="textarea"
              placeholder=""
              onChange={e => this.onTextChange(e)}
              value={description}
              style={{ width: '100%', height: '90' }}
            />
          </label>
          <PhotoSection
            photo={photo}
            handleCropComplete={photo => this.setState({ photo })}
            removePhoto={() => this.setState({ photo: null })}
          />
          <h4>Permissions:</h4>
          <label HTMLfor="canCreate">Can Create?</label>{' '}
          <input
            onChange={this.handleCheck}
            checked={canCreate}
            name="canCreate"
            type="checkbox"
            style={{ width: 'unset', marginRight: '10px' }}
          />
          <label HTMLfor="canEdit">Can Edit?</label>{' '}
          <input
            onChange={this.handleCheck}
            checked={canEdit}
            name="canEdit"
            type="checkbox"
            style={{ width: 'unset', marginRight: '10px' }}
          />
          <label HTMLfor="canDelete">Can Delete?</label>{' '}
          <input
            onChange={this.handleCheck}
            checked={canDelete}
            name="canDelete"
            type="checkbox"
            style={{ width: 'unset', marginRight: '10px' }}
          />
          {errors && this.renderErrors()}
          <div>{loading ? 'Creating user . . . ' : this.renderButton()}</div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  const { errors, loading } = user;
  return { errors, loading };
};

export default connect(
  mapStateToProps,
  actions
)(CreateUser);

CreateUser.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  emailChanged: PropTypes.func.isRequired,
  passwordChanged: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
};
