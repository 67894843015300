import {
  CREATE_USER,
  EDIT_USER,
  DELETE_USER,
  TOGGLE_USER_MODAL,
  OPEN_USER_MODAL,
  CLOSE_USER_MODAL,
} from '../actions/types';

const INITIAL_STATE = {
  response: {},
  errors: {},
  loading: false,
  updateResponse: {},
  deleteResponse: {},
  userModalIsOpen: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_USER.PENDING:
      return { ...state, loading: true };
    case CREATE_USER.SUCCESS:
      return { ...state, response: action.payload, loading: false };
    case CREATE_USER.FAIL:
      return { ...state, errors: action.payload, loading: false };
    case EDIT_USER.PENDING:
      return { ...state, loading: true };
    case EDIT_USER.SUCCESS:
      return { ...state, updateResponse: action.payload, loading: false };
    case EDIT_USER.FAIL:
      return { ...state, errors: action.payload, loading: false };
    case DELETE_USER.PENDING:
      return { ...state, loading: true };
    case DELETE_USER.SUCCESS:
      return { ...state, deleteResponse: action.payload, loading: false };
    case DELETE_USER.FAIL:
      return { ...state, errors: action.payload, loading: false };
    case TOGGLE_USER_MODAL:
      return { ...state, userModalIsOpen: !state.userModalIsOpen };
    case OPEN_USER_MODAL:
      return { ...state, userModalIsOpen: true };
    case CLOSE_USER_MODAL:
      return { ...state, userModalIsOpen: false };

    default:
      return state;
  }
};
