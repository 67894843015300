import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ParallaxProvider } from 'react-scroll-parallax';

import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { history, persistor, store } from './store';
import './styles/App.css';
import './fonts/GothamRnd-Book.otf';
import './fonts/GothamRnd-Light.otf';
import './fonts/WarmScript-Regular.otf';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <ParallaxProvider>
          <App />
        </ParallaxProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

registerServiceWorker();
