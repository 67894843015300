import React from 'react';

const TextInput = ({ labelName, name, onChange, textArea = false, value }) => {
  function render() {
    return textArea ? (
      <textarea
        className={`text-input ${name} full-width`}
        id={name}
        type="text-area"
        value={value}
        onChange={event => onChange({ [name]: event.target.value })}
      />
    ) : (
      <input
        className={`text-input ${name} full-width`}
        id={name}
        type="text"
        value={value}
        onChange={event => onChange({ [name]: event.target.value })}
      />
    );
  }

  return (
    <label htmlFor={name}>
      {`${labelName || name}`.toUpperCase()} <br />
      {render()}
    </label>
  );
};

export default TextInput;
