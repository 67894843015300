import React from 'react';

import TextInput from './text-input';

function TextFields({
  category,
  description,
  eventDate,
  handleChange,
  handleSubmit,
  name,
  vendor1Name,
  vendor1Url,
  vendor2Name,
  vendor2Url,
  vendor3Name,
  vendor3Url,
  title,
}) {
  return (
    <form className="text-fields-section" onSubmit={handleSubmit}>
      <TextInput name="name" value={name} onChange={handleChange} />
      <br />
      <TextInput name="category" value={category} onChange={handleChange} />
      <br />
      <TextInput
        labelName="event date"
        name="eventDate"
        value={eventDate}
        onChange={handleChange}
      />
      <br />
      <TextInput name="title" value={title} onChange={handleChange} />
      <br />
      <TextInput
        name="description"
        textArea
        value={description}
        onChange={handleChange}
      />
      <br />
      <TextInput
        labelName="vendor 1 name"
        name="vendor1Name"
        value={vendor1Name}
        onChange={handleChange}
      />
      <br />
      <TextInput
        labelName="vendor 1 url"
        name="vendor1Url"
        value={vendor1Url}
        onChange={handleChange}
      />
      <br />
      <TextInput
        labelName="vendor 2 name"
        name="vendor2Name"
        value={vendor2Name}
        onChange={handleChange}
      />
      <br />
      <TextInput
        labelName="vendor 2 url"
        name="vendor2Url"
        value={vendor2Url}
        onChange={handleChange}
      />
      <br />
      <TextInput
        labelName="vendor 3 name"
        name="vendor3Name"
        value={vendor3Name}
        onChange={handleChange}
      />
      <br />
      <TextInput
        labelName="vendor 3 url"
        name="vendor3Url"
        value={vendor3Url}
        onChange={handleChange}
      />
    </form>
  );
}

export default TextFields;
