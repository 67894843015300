import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import DropZoneContainer from './drop-zone';
import { LANDSCAPE, MIN_SIZE, PORTRAIT } from '../../utilities';

export default class PhotoCropper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      crop: {
        unit: '%',
        width: 25,
        aspect: this.props.orientation.ratio,
      },
      message: '',
    };

    this.onClickDone = this.onClickDone.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.renderCropper = this.renderCropper.bind(this);
    this.selectRatio = this.selectRatio.bind(this);
  }

  onDrop = files => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      return this.setState({ src: reader.result });
    });
    reader.readAsDataURL(files[0]);
  };

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropRelease = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  onClickDone = croppedImageData => {
    this.props.onClickDone(croppedImageData, this.props.index);
    this.setState({ src: null });
  };

  async makeClientCrop(crop) {
    const orientation = crop.aspect == LANDSCAPE.ratio ? LANDSCAPE : PORTRAIT;
    const minWidth = orientation.width;
    const minHeight = orientation.height;
    const refRatio = this.imageRef.width / this.imageRef.naturalWidth;
    if (this.imageRef && crop.width && crop.height) {
      const CROP_WIDTH = minWidth * refRatio;
      if (crop.width < CROP_WIDTH) {
        this.setState(
          {
            message: `Minimum image size is ${minWidth}px, larger crops make for cleaner images!`,
          },
          () => setTimeout(() => this.setState({ message: '' }), 5000)
        );
        crop.width = minWidth * refRatio;
        crop.height = minHeight * refRatio;
      }

      const fileName = orientation.tag + new Date().toISOString();
      const croppedImageData = await this.getCroppedImg(
        this.imageRef,
        crop,
        fileName,
        orientation
      );

      this.setState({ croppedImageData });
    }
  }

  getCroppedImg(image, crop, fileName, orientation) {
    const origScaleX = image.naturalWidth / image.width;
    const origScaleY = image.naturalHeight / image.height;
    let adjustedScaleX = origScaleX;
    let adjustedScaleY = origScaleY;
    if (origScaleX > 5) adjustedScaleX = 3;
    if (origScaleX < 1) adjustedScaleX = 1;
    if (origScaleY > 5) adjustedScaleY = 3;
    if (origScaleY < 1) adjustedScaleY = 1;

    const canvas = document.createElement('canvas');
    canvas.width = crop.width * adjustedScaleX;
    canvas.height = crop.height * adjustedScaleY;
    console.log('Image width', image.width);
    console.log('Image natural width', image.naturalWidth);
    console.log('Image height', image.height);
    console.log('Image natural height', image.naturalHeight);

    console.log('orientation', orientation.width, orientation.height);
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * origScaleX,
      crop.y * origScaleY,
      crop.width * origScaleX,
      crop.height * origScaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve({ name: fileName, blobUrl: this.fileUrl, blob });
        },
        'image/jpeg',
        0.95
      );
    });
  }

  selectRatio(ratio) {
    const oldCrop = this.state.crop;
    this.setState({
      crop: {
        aspect: ratio,
        x: 0,
        y: 0,
        width: MIN_SIZE,
        height: MIN_SIZE / ratio,
        unit: 'px',
      },
    });
  }

  renderCropper({ crop, croppedImageData, src }) {
    if (src) {
      return (
        <div className={`cropper ${this.props.orientation.tag}`}>
          <div
            className="add-photo"
            onClick={() => this.onClickDone(croppedImageData)}
          >
            Done Editing
          </div>
          {/* <RatioPicker onClick={this.selectRatio} value={crop.aspect} /> */}
          <ReactCrop
            src={src}
            crop={crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropRelease}
            onChange={this.onCropChange}
          />
          <br />
        </div>
      );
    }
    return null;
  }

  render() {
    const { crop, croppedImageData, src, message } = this.state;
    const { orientation } = this.props;
    return (
      <div className="photo-cropper">
        <div className="crop-warning" style={{ color: 'red' }}>
          {message}
        </div>
        <DropZoneContainer
          isFileLoaded={!!src}
          orientation={orientation}
          onDrop={this.onDrop}
        />
        {this.renderCropper({ crop, croppedImageData, src })}
      </div>
    );
  }
}
