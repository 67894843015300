import React, { Component } from 'react';

export default class Panel extends Component {
  displayText() {
    const { text } = this.props;

    if (text.length === 1) {
      return (
        <React.Fragment>
          <div className="panel-font-1 script">{text[0].textOne}</div>
          <div className="panel-font-2 gothic">{text[0].textTwo}</div>
          <div className="panel-font-2 gothic">{text[0].textThree}</div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="panel-font-1 script">{text[0].textOne}</div>
        <div className="panel-font-2">{text[0].textTwo}</div>
        <div className="panel-font-3">{text[0].textThree}</div>
        <div className="panel-font-4">{text[0].textFour}</div>

        <div className="panel-font-1 script">{text[1].textOne}</div>
        <div className="panel-font-2">{text[1].textTwo}</div>
        <div className="panel-font-3">{text[1].textThree}</div>
        <div className="panel-font-4">{text[1].textFour}</div>
      </React.Fragment>
    );
  }

  render() {
    const { backgroundColor, showImage, className } = this.props;

    return (
      <div className={`panel background-${backgroundColor} ${className}`}>
        <div className="panel-text">
          <div className="">{this.displayText()}</div>
        </div>

        <div className="panel-image">
          <div className="">
            {showImage && (
              <div className="panel-image-src">
                <img src="map.png" />
              </div>
            )}
          </div>
        </div>

        {this.props.children}
      </div>
    );
  }
}
