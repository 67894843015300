import { SET_SPECIAL } from './types';
import axios from 'axios';

export const getSpecial = () => (dispatch, getState) => {
  axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/api/specials`,
  }).then(response => {
    dispatch({ type: SET_SPECIAL, payload: response.data });
  });
};

export const createSpecial = values => (dispatch, getState) => {
  axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/api/specials`,
    data: values,
    headers: {
      Authorization: `${getState().auth.token}`,
    },
  }).then(response => {
    dispatch({ type: SET_SPECIAL, payload: response.data });
  });
};
