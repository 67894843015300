import React from 'react';
import DropZone from 'react-dropzone';

export default function DropZoneContainer(props) {
  const button = props.isFileLoaded ? null : (
    <div className={`dropzone ${props.orientation.tag}`}>
      <div className="add-photo">Add Photo</div>
    </div>
  );

  return (
    <DropZone onDrop={acceptedFiles => props.onDrop(acceptedFiles)}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {button}
          </div>
        </section>
      )}
    </DropZone>
  );
}
