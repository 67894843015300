import React, { Component, useContext } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { deleteEvent } from '../../../actions';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'unset',
  },
};

class DeleteEventButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  canUserDelete() {
    const { currentUser } = this.props;
    if (!currentUser) return false;
    return currentUser.isAdmin || currentUser.permissions.includes('DELETE');
  }

  render() {
    const { isModalOpen } = this.state;
    const { deleteEvent, event, loading, deleteError } = this.props;

    if (!this.canUserDelete()) return null;
    return (
      <React.Fragment>
        <div style={{ color: '#FF0000', float: 'right', cursor: 'pointer' }}>
          <i
            className="fa fa-trash"
            aria-hidden="true"
            onClick={this.toggleModal}
          ></i>
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={this.toggleModal}
          style={customStyles}
          contentLabel="Delete"
        >
          <p>Are you sure you want to delete this event?</p>
          {loading ? (
            <p>Deleting...</p>
          ) : (
            <React.Fragment>
              <div className="d-flex flex-row">
                <button
                  className="btn btn-success"
                  onClick={() => deleteEvent(event)}
                >
                  Yes
                </button>
                <button
                  className="btn btn-danger ml-3"
                  onClick={this.toggleModal}
                >
                  No
                </button>
              </div>
              {deleteError && <p className="text-danger">{deleteError}</p>}
            </React.Fragment>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteEvent: event => dispatch(deleteEvent(event)),
});

const mapStateToProps = state => ({
  currentUser: state.auth.user,
  deleteError: state.events.deleteError,
  loading: state.events.loading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteEventButton);
