import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const NavBar = ({ currentUser, logoutUser }) => {
  const [isCollapsed, setCollapsed] = useState(true);

  const TogglerButton = () => (
    <button
      className="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavAltMarkup"
      aria-label="Toggle navigation"
      onClick={() => setCollapsed(!isCollapsed)}
    >
      <span className="navbar-toggler-icon"></span>
    </button>
  );

  return (
    <nav
      className="row navbar navbar-expand-sm navbar-light bg-light gothic px-5 py-3"
      style={{ width: '100%' }}
    >
      <Link to="/" className="col-3 navbar-brand">
        <img
          alt="home-link"
          className="home-link-image"
          src={
            'https://s3.amazonaws.com/papertini/home_screen/01_header/logo+only.svg'
          }
        />
      </Link>
      <TogglerButton />
      <div
        className={`collapse navbar-collapse ${
          isCollapsed ? '' : 'show'
        }  justify-content-end`}
        id="navbarNavAltMarkup"
      >
        <div className="navbar-nav w-75 justify-content-around">
          <Link className="nav-item nav-link" to="/portfolio">
            PORTFOLIO
          </Link>
          <Link className="nav-item nav-link" to="/contact">
            ABOUT
          </Link>
          {currentUser ? (
            <React.Fragment>
              <Link to="/employee" className="nav-item nav-link">
                EMPLOYEE PORTAL
              </Link>
              <Link
                to="/"
                className="nav-item nav-link"
                onClick={() => logoutUser()}
              >
                LOG OUT
              </Link>
            </React.Fragment>
          ) : (
            <a
              className="nav-item nav-link "
              href="https://www.trelliseventsoftware.com/A/I/66"
            >
              SCHEDULE A CONSULT
            </a>
          )}
        </div>
        {/* <div className="col-sm" /> */}
      </div>
    </nav>
  );
};

export default NavBar;
