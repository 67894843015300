import {
  CHECK_SESSION,
  EMAIL_CHANGED,
  PASSWORD_CHANGED,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGIN_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAIL,
  LOGOUT_USER,
  SHOW_FORGOT_PASSWORD,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
} from '../actions/types';

const INITIAL_STATE = {
  email: '',
  password: '',
  token: '',
  user: null,
  error: '',
  loading: false,
  forgotPassword: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHECK_SESSION.FAIL:
      return { ...state, ...INITIAL_STATE };
    case EMAIL_CHANGED:
      return { ...state, email: action.payload };
    case PASSWORD_CHANGED:
      return { ...state, password: action.payload };
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        token: action.token,
        user: action.user,
      };
    case LOGIN_USER_FAIL:
      return {
        ...state,
        error: 'Authentication Failed.',
        password: '',
        loading: false,
      };
    case LOGOUT_USER:
      return { ...state, loading: true, error: '' };
    case LOGOUT_USER_SUCCESS:
      return { ...state, ...INITIAL_STATE };
    case LOGOUT_USER_FAIL:
      return { ...state, error: 'Logout Failed.', loading: false };
    case SHOW_FORGOT_PASSWORD:
      return { ...state, forgotPassword: action.payload };
    case FORGOT_PASSWORD:
      return { ...state, loading: true };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: 'Reset link sent' };
    case FORGOT_PASSWORD_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
