import React, { useEffect } from 'react';
import 'react-animated-slider/build/horizontal.css';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { withController } from 'react-scroll-parallax';

import * as actions from '../../../actions';

import {
  About,
  Cross,
  CurrentDeal,
  HeaderImage,
  FlowerA,
  FlowerB,
  FlowerC,
  FlowerD,
  Quote,
  Slider,
  HighlightTile,
} from './subcomponents';
import InstagramSection from './subcomponents/insta-row';

function Home({
  currentDeal,
  events,
  getEvents,
  getInstagramData,
  instagram,
  parallaxController,
  teamMembers,
}) {
  useEffect(() => {
    getEvents();
    getInstagramData();
  }, []);

  function handleLoad() {
    parallaxController.update();
  }

  const instagramData = instagram.response;

  return (
    <React.Fragment>
      <HeaderImage onLoad={handleLoad} />
      <About />
      {/* <FlowerA isHidden={isMobile} /> */}

      {/* <Slider isHidden={!teamMembers} teamMembers={teamMembers} /> */}

      {/* <FlowerB isHidden={isMobile}/> */}

      <Cross />

      <div className="highlight-section" style={{ width: '90%' }}>
        <div className="row">
          <div className="col-md tile-1">
            <FlowerC isHidden={isMobile} />

            <HighlightTile
              event={events && events.find(e => e.homeSlot === 1)}
            />
          </div>

          <div className="col-md tile-2">
            <CurrentDeal currentDeal={currentDeal} isHidden={!currentDeal} />
          </div>
        </div>
        <div className="row">
          <FlowerD isHidden={isMobile} />
        </div>
        <div className="row quote-container">
          <Quote />
        </div>
        <div className="row">
          <div className="col-md tile-3">
            <HighlightTile
              event={events && events.find(e => e.homeSlot === 2)}
            />
          </div>

          <div className="col-md tile-4">
            <HighlightTile
              event={events && events.find(e => e.homeSlot === 3)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md tile-5">
            <HighlightTile
              event={events && events.find(e => e.homeSlot === 4)}
            />
          </div>
          <div className="col-md tile-4"></div>
        </div>
      </div>

      <InstagramSection
        instagramData={instagramData}
        isHidden={!instagramData}
      />
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  currentDeal: state.special.currentDeal,
  currentUser: state.auth.user,
  events: state.events.all,
  instagram: state.instagram,
  teamMembers: state.carousel.users,
});

export default withController(
  connect(
    mapStateToProps,
    actions
  )(Home)
);
