import { LOAD_ALL_USERS, SET_ALL_USERS, ADD_USER } from '../actions/types';

const INITIAL_STATE = {
  users: null,
  error: '',
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_ALL_USERS:
      return { ...state, loading: true };
    case SET_ALL_USERS:
      return { ...state, users: action.payload, loading: false };
    case ADD_USER:
      return {
        ...state,
        users: [...state.users, action.payload],
        loading: false,
      };

    default:
      return state;
  }
};
