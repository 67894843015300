import React from 'react';

import * as TEXT from '../text';

function About() {
  return (
    <div className="about-papertini">
      <div className="gothic header">ABOUT PAPERTINI</div>
      <div className="script body">{TEXT.ABOUT_PAPERTINI}</div>
    </div>
  );
}

export default About;
