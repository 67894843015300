import { SET_INSTAGRAM_DATA, LOAD_INSTAGRAM_DATA } from './types';

export const getInstagramData = () => dispatch => {
  dispatch({ type: LOAD_INSTAGRAM_DATA });
  const url =
    'https://graph.instagram.com/v1.0/me/media?fields=id,media_type,media_url,permalink,thumbnail_url,username,timestamp&access_token=IGQVJWMkZAmMGt1MDN4ZADV0U1d6TzlkdmhTeWd5TVdnQVlOXzE5TDBFLV8yaGs1Yi1Ed0QxS1JlRmdYVjNUQlVNaTdxYjlfRl9xb3JDRWFMZA3ZASNVk5Mlp2V3hWT2hqamZAFWDNHbEgyWGdUSGF2OVZATVwZDZD';

  fetch(url)
    .then(res => res.json())
    .then(body => {
      dispatch({ type: SET_INSTAGRAM_DATA, payload: body.data });
    });
};
