export const CREATE_EVENT = {
  PENDING: 'create_event_pending',
  SUCCESS: 'create_event_success',
  FAIL: 'create_event_fail',
};

export const CHECK_SESSION = {
  PENDING: 'check_session_pending',
  SUCCESS: 'check_session_success',
  FAIL: 'check_session_fail',
};

export const DELETE_EVENT = {
  PENDING: 'delete_event_pending',
  SUCCESS: 'delete_event_success',
  FAIL: 'delete_event_fail',
};

export const EMAIL_CHANGED = 'email_changed';
export const PASSWORD_CHANGED = 'password_changed';

export const LOGIN_USER_SUCCESS = 'login_user_success';
export const LOGIN_USER_FAIL = 'login_user_fail';
export const LOGIN_USER = 'login_user';

export const LOGOUT_USER_SUCCESS = 'logout_user_success';
export const LOGOUT_USER_FAIL = 'logout_user_fail';
export const LOGOUT_USER = 'logout_user';

export const SEND_CONTACT = 'send_contact';
export const SEND_CONTACT_CLEAR_ERROR = 'send_contact_clear_error';
export const SEND_CONTACT_CLEAR_SUCCESS = 'send_contact_clear_success';
export const SEND_CONTACT_SUCCESS = 'send_contact_success';
export const SEND_CONTACT_FAIL = 'send_contact_fail';

export const LOAD_ALL_USERS = 'load_all_users';
export const SET_ALL_USERS = 'set_all_users';
export const ADD_USER = 'add_user';
export const SHOW_FORGOT_PASSWORD = 'show_forgot_password';

export const FORGOT_PASSWORD = 'submit_forgot_password';
export const FORGOT_PASSWORD_SUCCESS = 'submit_forgot_password_success';
export const FORGOT_PASSWORD_FAIL = 'submit_forgot_password_fail';

export const SET_INSTAGRAM_DATA = 'set_instagram_data';
export const LOAD_INSTAGRAM_DATA = 'load_instagram_data';

export const TOGGLE_USER_MODAL = 'toggle_user_modal';
export const OPEN_USER_MODAL = 'open_user_modal';
export const CLOSE_USER_MODAL = 'close_user_modal';

export const SET_SPECIAL = 'set_special';

export const CREATE_USER = {
  PENDING: 'create_user_pending',
  SUCCESS: 'create_user_success',
  FAIL: 'create_user_fail',
};

export const EDIT_USER = {
  PENDING: 'edit_user_pending',
  SUCCESS: 'edit_user_success',
  FAIL: 'edit_user_fail',
};

export const GET_EVENTS = {
  PENDING: 'get_events_pending',
  SUCCESS: 'get_events_success',
  FAIL: 'get_events_fail',
};

export const DELETE_USER = {
  PENDING: 'delete_user_pending',
  SUCCESS: 'delete_user_success',
  FAIL: 'delete_user_fail',
};

export const UPLOAD_PHOTO = {
  PENDING: 'upload_photo_pending',
  SUCCESS: 'upload_photo_success',
  FAIL: 'upload_photo_fail',
};
