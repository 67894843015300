/* eslint-disable  */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getEvents } from '../../../actions';

function detailsColor(num) {
  if (num % 9 === 0 || num % 9 === 4 || num % 9 === 8) {
    return '#F2D2C3';
  }

  if (num % 9 === 1 || num % 9 === 5 || num % 9 === 6) {
    return '#FBEEE8';
  }

  return '#F5DDD3';
}

class Portfolio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      showMoreCount: 9,
    };
  }

  componentDidMount() {
    this.props.getEvents();
  }

  render() {
    const { search, showMoreCount } = this.state;

    let events = this.props.events || [];

    if (search) {
      events = events.filter(photo => {
        const { title, category, name } = photo;
        const textBlob = `${title} ${category} ${name}`;

        return textBlob.toLowerCase().includes(search.toLowerCase());
      });
    }

    let photosToShow;
    let allPhotosShown = false;

    if (events.length > showMoreCount) {
      photosToShow = events.slice(0, showMoreCount);
    } else {
      photosToShow = events;
      allPhotosShown = true;
    }

    return (
      <div className="portfolio">
        <div className="search-container">
          <label className="row">SEARCH</label>
          <input
            className="row search-box"
            name="search-box"
            type="text"
            value={this.state.search}
            onChange={e => this.setState({ search: e.target.value })}
          />
        </div>
        <div className="thumbnail-container">
          {photosToShow.map((event, index) => {
            return (
              <Link to={{ pathname: `/portfolio/${event._id}` }}>
                {event.photos[0] && (
                  <div className="portfolio-event-card" key={index}>
                    <img
                      alt="event thumbnail"
                      className="portfolio-thumbnail"
                      src={event.photos[0].url}
                    />
                    <div
                      className="portfolio-details"
                      style={{ backgroundColor: detailsColor(index) }}
                    >
                      <div
                        className="script"
                        style={{
                          fontSize: '40px',
                          marginBottom: '10px',
                        }}
                      >
                        {event.category}
                      </div>
                      <div
                        style={{
                          fontFamily: 'Gothic A1',
                          fontSize: '13px',
                          fontWeight: '100',
                          letterSpacing: '5px',
                          marginBottom: '7.5%',
                          textTransform: 'uppercase',
                        }}
                      >
                        {event.name}
                      </div>
                    </div>
                  </div>
                )}
              </Link>
            );
          })}
          {allPhotosShown && search && <div className="portfolio-ghost-card" />}
          {allPhotosShown && search && <div className="portfolio-ghost-card" />}
          {allPhotosShown && search && <div className="portfolio-ghost-card" />}
          {!allPhotosShown && (
            <div
              className="click-more script"
              onClick={() =>
                this.setState({ showMoreCount: this.state.showMoreCount + 9 })
              }
            >
              <div
                style={{
                  fontSize: '40px',
                  marginBottom: '-10px',
                }}
              >
                click to
              </div>
              <div
                style={{
                  fontFamily: 'Gothic A1',
                  fontSize: '13px',
                  fontWeight: '100',
                  letterSpacing: '5px',
                  marginBottom: '7.5%',
                }}
              >
                VIEW MORE
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getEvents: () => dispatch(getEvents()),
  setUser: () => dispatch(),
});

const mapStateToProps = state => ({
  currentUser: state.auth.user,
  events: state.events.all,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Portfolio);
