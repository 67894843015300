import { SET_ALL_USERS, LOAD_ALL_USERS } from './types';

export const getAllUsers = () => dispatch => {
  dispatch({ type: LOAD_ALL_USERS });

  fetch(`${process.env.REACT_APP_API_URL}/api/users/`)
    .then(res => res.json())
    .then(body => {
      dispatch({ type: SET_ALL_USERS, payload: body });
    });
};
