import React from 'react';

import { PhotoCropper } from '../../../../../shared-components';
import { FORMAT_MATRIX } from '../../../../../utilities';

function PhotoSection({ handleCropComplete, photos, removePhoto }) {
  function Photo(props, i) {
    const { blobUrl, index, orientation } = props;

    return (
      <div className={`image ${orientation.tag}`} key={blobUrl}>
        <span className="close-button" onClick={() => removePhoto(index)}>
          <i className="far fa-window-close fa-2x" />
        </span>
        <img alt="" src={blobUrl} />
      </div>
    );
  }

  function renderFormat() {
    let index = 0;

    return FORMAT_MATRIX.map((rowArr, i) => {
      const rowUploaders = rowArr.map(orientation => {
        index++;

        return photos[index] ? (
          <Photo
            blobUrl={photos[index].blobUrl}
            key={index}
            index={index}
            orientation={orientation}
          />
        ) : (
          <PhotoCropper
            index={index}
            key={index}
            orientation={orientation}
            onClickDone={handleCropComplete}
          />
        );
      });

      return (
        <div className="row photo-row" key={i}>
          {rowUploaders}
        </div>
      );
    });
  }

  return <div className="photo-section">{renderFormat()}</div>;
}

export default PhotoSection;
