import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import Modal from 'react-modal';
import PhotoSection from './photo-section';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      title: '',
      email: '',
      canCreate: false,
      canEdit: false,
      canDelete: false,
      description: '',
      photo: '',
    };
  }

  generatePermissionsArray = () => {
    const { canCreate, canEdit, canDelete } = this.state;

    let permissions = [];
    if (canCreate) {
      permissions.push('CREATE');
    }
    if (canEdit) {
      permissions.push('UPDATE');
    }
    if (canDelete) {
      permissions.push('DELETE');
    }
    return permissions;
  };

  onSubmit = e => {
    e.preventDefault();
    const { name, email, description, photo, title } = this.state;
    const { editUser } = this.props;
    const permissions = this.generatePermissionsArray();

    editUser({ name, email, description, permissions, photo, title });
  };

  onDelete = e => {
    e.preventDefault();
    const { name, email } = this.state;
    const { deleteUser } = this.props;

    deleteUser({ name, email });
  };

  onTextChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheck = e => {
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  };

  deconstructPermissionsArray = permissionsArray => {
    let canCreate = false,
      canEdit = false,
      canDelete = false;
    if (permissionsArray.includes('CREATE')) {
      canCreate = true;
    }
    if (permissionsArray.includes('UPDATE')) {
      canEdit = true;
    }
    if (permissionsArray.includes('DELETE')) {
      canDelete = true;
    }
    return { canCreate, canEdit, canDelete };
  };

  onUserClick = (e, index) => {
    const { users, openUserModal } = this.props;
    const selectedUser = users[index];
    const selectedUserPermissions = this.deconstructPermissionsArray(
      selectedUser.permissions
    );
    this.setState({
      name: selectedUser.name,
      email: selectedUser.email,
      description: selectedUser.description,
      title: selectedUser.title,
      photo: selectedUser.photo,
      ...selectedUserPermissions,
    });
    openUserModal();
  };

  renderButton = () => {
    if (this.props.loading) {
      return <p>L o a d i n g . . .</p>;
    }

    return (
      <button className="btn" type="submit">
        Update User
      </button>
    );
  };

  renderDeleteButton = () => {
    if (this.props.loading) {
      return <p>L o a d i n g . . .</p>;
    }

    return (
      <button
        onClick={e => this.onDelete(e)}
        className="btn"
        type="submit"
        style={{ backgroundColor: 'red', marginTop: '12px' }}
      >
        Delete User
      </button>
    );
  };

  renderErrors = () => {
    return Object.values(this.props.errors).map(error => {
      return (
        <div className="error" style={{ color: 'red' }}>
          {error}
        </div>
      );
    });
  };

  render() {
    const {
      name,
      email,
      description,
      title,
      canCreate,
      canEdit,
      canDelete,
      photo,
    } = this.state;

    const {
      loading,
      errors,
      users,
      userModalIsOpen,
      closeUserModal,
    } = this.props;

    return (
      <React.Fragment>
        {users &&
          users.map((member, index) => {
            return (
              <div
                onClick={e => this.onUserClick(e, index)}
                style={{ width: 'auto' }}
              >
                <a href="#">{member.name}</a>
              </div>
            );
          })}
        <Modal
          isOpen={userModalIsOpen}
          onRequestClose={closeUserModal}
          style={customStyles}
          contentLabel="Update User Modal"
        >
          <div className="login-form edit-user">
            <h2>Update User</h2>
            <form className="box" onSubmit={this.onSubmit}>
              <input
                id="name"
                name="name"
                className="login-email"
                type="text"
                placeholder="name"
                onChange={e => this.onTextChange(e)}
                value={name}
              />
              <input
                id="title"
                name="title"
                className="login-email"
                type="text"
                placeholder="title"
                onChange={e => this.onTextChange(e)}
                value={title}
              />
              <input
                id="email"
                name="email"
                className="login-email"
                type="text"
                placeholder="Email"
                onChange={e => this.onTextChange(e)}
                value={email}
              />
              <label>
                Employee Description:
                <textarea
                  id="description"
                  name="description"
                  className="login-email"
                  type="text"
                  placeholder="description"
                  onChange={e => this.onTextChange(e)}
                  value={description}
                />
              </label>
              <PhotoSection
                photo={photo}
                handleCropComplete={photo => this.setState({ photo })}
                removePhoto={() => this.setState({ photo: null })}
              />
              <h4>Event Permissions:</h4>
              <label HTMLfor="canCreate">Can Create?</label>{' '}
              <input
                onChange={this.handleCheck}
                checked={canCreate}
                name="canCreate"
                type="checkbox"
                style={{ width: 'unset', marginRight: '10px' }}
              />
              {/* <label HTMLfor="canEdit">Can Edit?</label>{' '}
              <input
                onChange={this.handleCheck}
                checked={canEdit}
                name="canEdit"
                type="checkbox"
                style={{ width: 'unset', marginRight: '10px' }}
              />
              <label HTMLfor="canDelete">Can Delete?</label>{' '}
              <input
                onChange={this.handleCheck}
                checked={canDelete}
                name="canDelete"
                type="checkbox"
                style={{ width: 'unset', marginRight: '10px' }}
              /> */}
              {errors && this.renderErrors()}
              <div>
                {loading ? 'Updating user . . . ' : this.renderButton()}
              </div>
            </form>
            <div>{this.renderDeleteButton()}</div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ user, carousel }) => {
  const { errors, loading, userModalIsOpen } = user;
  const { users } = carousel;
  return { errors, loading, users, userModalIsOpen };
};

export default connect(
  mapStateToProps,
  actions
)(EditUser);
