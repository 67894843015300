/* eslint-disable camelcase */
import React, { useRef, useState } from 'react';
import _ from 'lodash';

const Instagram = ({ instagramData, isHidden }) => {
  const scrollRef = useRef(null);
  const [intervalRef, setIntervalRef] = useState(null);

  if (isHidden) return null;

  const onMouseEnter = () => {
    setIntervalRef(
      setInterval(
        () =>
          scrollRef.current.scroll({
            behavior: 'smooth',
            left: scrollRef.current.scrollLeft + 30,
          }),
        100
      )
    );
  };

  const onMouseLeave = () => {
    clearInterval(intervalRef);
    setIntervalRef(null);
  };

  return (
    <React.Fragment>
      <div className="center">
        <div className="script">our</div>
        <div className="gothic instagram">INSTAGRAM</div>
        <img
          alt="decorative-curl"
          className="svg-curl"
          src="https://s3.amazonaws.com/papertini/home_screen/misc_flowers/SVG+CURL.png"
        />
      </div>
      <div className="instagram-slider">
        <div className="instagram-photos" ref={scrollRef}>
          <span className="spacer" />
          {!_.isEmpty(instagramData) &&
            instagramData.map(
              ({ id, media_type, media_url, permalink, thumbnail_url }) => (
                <span key={id}>
                  <a href={permalink} rel="noopener noreferrer" target="_blank">
                    <img
                      alt="from instagram"
                      src={media_type === 'VIDEO' ? thumbnail_url : media_url}
                    />
                  </a>
                </span>
              )
            )}
        </div>
        <div
          className={`scroll-arrow ${intervalRef ? 'active' : 'inactive'}`}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <img
            alt="arrow to scroll right"
            src="https://s3.amazonaws.com/papertini/home_screen/02_about/ABOUT+ARROW.png"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Instagram;
