import {
  SEND_CONTACT_CLEAR_ERROR,
  SEND_CONTACT_CLEAR_SUCCESS,
  SEND_CONTACT_SUCCESS,
  SEND_CONTACT_FAIL,
  SEND_CONTACT,
} from '../actions/types';

const INITIAL_STATE = {
  error: null,
  isLoading: false,
  success: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEND_CONTACT:
      return { error: null, isLoading: true, success: null };
    case SEND_CONTACT_SUCCESS:
      return { error: null, isLoading: false, success: true };
    case SEND_CONTACT_FAIL:
      return { error: 'Form Send Failed.', isLoading: false, success: false };
    case SEND_CONTACT_CLEAR_ERROR:
      return { ...state, error: null };
    case SEND_CONTACT_CLEAR_SUCCESS:
      return { ...state, success: null };
    default:
      return state;
  }
};
