import React from 'react';

import * as TEXT from '../text';

function Quote() {
  return (
    <div className="tantiquote text-center">
      <div className="script text">{TEXT.TANTI_QUOTE}</div>
    </div>
  );
}

export default Quote;
