import React from 'react';

import { addLineBreaks } from '../../../../utilities';

function CurrentDeal({ currentDeal, isHidden }) {
  if (isHidden) return null;

  return (
    <div className="text-center centerelement">
      <div className="script" style={{ paddingBottom: 20 }}>
        {currentDeal.header}
      </div>
      <div className="gothic current-deal">
        {addLineBreaks(currentDeal.body)}
      </div>
    </div>
  );
}

export default CurrentDeal;
