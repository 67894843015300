import { CREATE_EVENT } from '../actions/types';

const INITIAL_STATE = {
  error: null,
  isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_EVENT.PENDING:
      return { ...state, isLoading: true };
    case CREATE_EVENT.SUCCESS:
      return { ...state, all: action.payload, isLoading: false };
    case CREATE_EVENT.FAIL:
      return { ...state, error: action.error, isLoading: false };
    default:
      return state;
  }
};
