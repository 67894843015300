import { push } from 'connected-react-router';

import { CREATE_EVENT, GET_EVENTS, UPLOAD_PHOTO, DELETE_EVENT } from './types';
import * as services from './services';

export const createEvent = formData => async (dispatch, getState) => {
  dispatch({ type: CREATE_EVENT.PENDING });

  const token = getState().auth.token;
  const { name, eventDate } = formData;
  const filePath = `event photos/${name.replace(/[^a-zA-Z0-9]/g, '')}${
    eventDate ? ' - ' & eventDate.replace(/[^a-zA-Z0-9]/g, '') : ''
  }`;
  formData.photos = formData.photos.filter(el => el);

  try {
    const photoUrls = await services.uploadPhotos(formData.photos, filePath);

    photoUrls.forEach((url, i) => {
      formData.photos[i].url = url;
    });

    await services.createEvent(formData, token);
    dispatch({ type: CREATE_EVENT.SUCCESS });
    dispatch(push('/portfolio'));
  } catch (error) {
    console.error('action error: createEvent', error);
    dispatch({ error, type: CREATE_EVENT.FAIL });
  }
};

export const getEvents = () => dispatch => {
  dispatch({ type: GET_EVENTS.PENDING });

  fetch(`${process.env.REACT_APP_API_URL}/api/events/`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
    .then(res => res.json())
    .then(events =>
      dispatch({
        type: GET_EVENTS.SUCCESS,
        payload: events.sort((a, b) => {
          a = new Date(a.createdAt);
          b = new Date(b.createdAt);
          return a > b ? -1 : a < b ? 1 : 0;
        }),
      })
    )
    .catch(() => dispatch({ type: GET_EVENTS.FAIL }));
};

export const deleteEvent = event => (dispatch, getState) => {
  dispatch({ type: DELETE_EVENT.PENDING });

  fetch(`${process.env.REACT_APP_API_URL}/api/events/delete`, {
    method: 'POST',
    body: JSON.stringify(event),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${getState().auth.token}`,
    },
  })
    .then(res => res.json())
    .then(() => dispatch({ type: DELETE_EVENT.SUCCESS, eventId: event._id }))
    .then(() => dispatch(push('/portfolio')))
    .catch(() => dispatch({ type: DELETE_EVENT.FAIL }));
};
