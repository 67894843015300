/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Link } from 'react-router-dom';

function HighlightTile({ event }) {
  if (!event) return null;

  return (
    <div className="highlight-tile">
      <Link to={{ pathname: `/portfolio/${event._id}` }}>
        {event && event.thumbnail && (
          <div className="portfolio-event-card" key={`${event.thumbnail}`}>
            <img
              alt="event thumbnail"
              className="portfolio-thumbnail"
              src={event.thumbnail.url}
            />
            <div
              className="portfolio-details"
              style={{ backgroundColor: '#F2D2C3' }}
            >
              <div
                className="script"
                style={{
                  fontSize: '40px',
                  marginBottom: '10px',
                }}
              >
                {event.category}
              </div>
              <div
                style={{
                  fontFamily: 'Gothic A1',
                  fontSize: '13px',
                  fontWeight: '100',
                  letterSpacing: '5px',
                  marginBottom: '7.5%',
                  textTransform: 'uppercase',
                }}
              >
                {event.name}
              </div>
            </div>
          </div>
        )}
      </Link>
    </div>
  );
}

export default HighlightTile;
