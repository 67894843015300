import { combineReducers } from 'redux';

import AuthReducer from './AuthReducer';
import ContactReducer from './ContactReducer';
import CarouselReducer from './CarouselReducer';
import EventsReducer from './EventsReducer';
import EventFormReducer from './EventFormReducer';
import InstagramReducer from './InstagramReducer';
import UserReducer from './UserReducer';
import SpecialReducer from './SpecialReducer';

export default combineReducers({
  auth: AuthReducer,
  contact: ContactReducer,
  carousel: CarouselReducer,
  events: EventsReducer,
  eventForm: EventFormReducer,
  instagram: InstagramReducer,
  user: UserReducer,
  special: SpecialReducer,
});
