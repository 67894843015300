import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { Link } from 'react-router-dom';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forgotPassword: false,
    };
  }

  onSubmit = event => {
    event.preventDefault();
    const { email, password, loginUser } = this.props;

    loginUser({ email, password });
  };

  renderButton() {
    if (this.props.loading) {
      return <p>L o a d i n g . . .</p>;
    }

    return (
      <button
        className="btn"
        style={{ backgroundColor: '#e9aba3' }}
        type="submit"
      >
        Login
      </button>
    );
  }

  render() {
    const {
      email,
      password,
      error,
      emailChanged,
      passwordChanged,
    } = this.props;

    return (
      <div className="login-form gothic">
        <h2>Log In</h2>
        <form className="box" onSubmit={this.onSubmit}>
          <div>
            <input
              id="email"
              className="login-email"
              type="text"
              placeholder="Email"
              onChange={e => emailChanged(e.target.value)}
              value={email}
            />
          </div>

          <div>
            <input
              id="password"
              className="login-password"
              type="password"
              placeholder="Password"
              onChange={e => passwordChanged(e.target.value)}
              value={password}
            />
          </div>

          <div>{error}</div>

          <div>{this.renderButton()}</div>
        </form>

        <Link to="/forgotpassword">
          <div style={{ marginTop: '25px' }}>
            <u style={{ color: '#e9aba3' }}>FORGOT YOUR PASSWORD?</u>
          </div>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { email, password, error, loading, forgotPassword } = auth;

  return { email, password, error, loading, forgotPassword };
};

export default connect(
  mapStateToProps,
  actions
)(Login);

Login.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  emailChanged: PropTypes.func.isRequired,
  passwordChanged: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
};
