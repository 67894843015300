import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../actions';
import { TextFieldsSection, PhotoSection } from './sections';

class EventForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: '',
      description: '',
      eventDate: '',
      name: '',
      photos: [],
      title: '',
      vendor1Name: '',
      vendor1Url: '',
      vendor2Name: '',
      vendor2Url: '',
      vendor3Name: '',
      vendor3Url: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCropComplete = this.handleCropComplete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
    this.isValidData = this.isValidData.bind(this);
  }

  handleCropComplete(imageData, index) {
    const { photos } = this.state;
    const newPhotos = [...photos];

    newPhotos[index] = imageData;
    this.setState({ photos: newPhotos });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { createEvent } = this.props;
    const formData = { ...this.state };

    if (this.isValidData(formData)) {
      createEvent(formData);
    }
  }

  isValidData(formData) {
    const { category, name, photos } = formData;

    if (!name) {
      window.alert('Name cannot be blank');

      return false;
    }

    if (!category) {
      window.alert('Category cannot be blank');

      return false;
    }

    if (!photos[1]) {
      window.alert('First photo required');

      return false;
    }

    return true;
  }

  handleChange(data) {
    this.setState({ ...data });
  }

  removePhoto(index) {
    const { photos } = this.state;
    const newPhotos = [...photos];

    newPhotos[index] = undefined;
    this.setState({ photos: newPhotos });
  }

  render() {
    const { error, isLoading } = this.props;
    const {
      category,
      name,
      title,
      description,
      eventDate,
      photos,
      vendor1Name,
      vendor1Url,
      vendor2Name,
      vendor2Url,
      vendor3Name,
      vendor3Url,
    } = this.state;

    const displayError = error === 401 ? 'Unauthorized' : error;

    return (
      <div className="event-form">
        {isLoading && <div className="loading-indicator" />}
        <br />
        <div>New Event Form</div>
        <br />
        <TextFieldsSection
          category={category}
          description={description}
          eventDate={eventDate}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          name={name}
          vendor1Name={vendor1Name}
          vendor1Url={vendor1Url}
          vendor2Name={vendor2Name}
          vendor2Url={vendor2Url}
          vendor3Name={vendor3Name}
          vendor3Url={vendor3Url}
          title={title}
        />
        <PhotoSection
          handleCropComplete={this.handleCropComplete}
          photos={photos}
          removePhoto={this.removePhoto}
        />
        <div className="error">{`${displayError || ''}`}</div>
        <button className="submit" onClick={this.handleSubmit}>
          Submit Event
        </button>
      </div>
    );
  }
}

const mapDispatchToProps = {
  createEvent: actions.createEvent,
};

const mapStateToProps = state => ({
  currentUser: state.auth.user,
  error: state.eventForm.error,
  isLoading: state.eventForm.isLoading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventForm);
