import { GET_EVENTS, DELETE_EVENT } from '../actions/types';

const INITIAL_STATE = {
  all: null,
  errors: null,
  deleteError: null,
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EVENTS.PENDING:
      return { ...state, loading: true };
    case GET_EVENTS.SUCCESS:
      return { ...state, all: action.payload, loading: false };
    case GET_EVENTS.FAIL:
      return { ...state, errors: action.error, loading: false };
    case DELETE_EVENT.PENDING:
      return { ...state, deleteError: null, loading: true };
    case DELETE_EVENT.SUCCESS:
      const allEvents = [...state.all];
      const indexOfEventToDelete = allEvents.findIndex(
        event => (event._id = action.eventid)
      );
      const updatedEvents = allEvents.slice(indexOfEventToDelete, 1);
      return { ...state, all: updatedEvents, loading: false };
    case DELETE_EVENT.FAIL:
      return {
        ...state,
        deleteError:
          'Something went wrong. Check if you have DELETE permission and try again.',
        loading: false,
      };
    default:
      return state;
  }
};
