import React, { Component } from 'react';
import { connect } from 'react-redux';

import { FORMAT_MATRIX } from '../../../utilities';
import { getEvents } from '../../../actions';
import DeleteEventButton from './deleteEventButton';

class EventShow extends Component {
  componentDidMount() {
    this.props.getEvents();
  }

  render() {
    let event = this.props.location.event || {};

    if (this.props.events && this.props.match.params) {
      event =
        this.props.events.find(
          event => event._id === this.props.match.params.id
        ) || {};
    }

    const {
      title,
      description,
      eventDate,
      name,
      vendor1Url,
      vendor1Name,
      vendor2Url,
      vendor2Name,
      vendor3Url,
      vendor3Name,
      photos,
    } = event;

    let index = -1;

    const renderPhotos = FORMAT_MATRIX.map(rowArr => {
      const rowOfPhotos = rowArr.map(orientation => {
        index++;
        const isPhotoAvailable =
          !!photos && !!photos[index] && !!photos[index].url;

        return isPhotoAvailable ? (
          <div className={`image ${orientation.tag}`} key={index}>
            <img src={photos[index].url} alt="" />
          </div>
        ) : null;
      });

      return <div className="row photo-row">{rowOfPhotos}</div>;
    });

    const renderVendorLinks = () => {
      const names = [vendor1Name, vendor2Name, vendor3Name];
      const links = [vendor1Url, vendor2Url, vendor3Url];
      const nodes = links
        .map((url, i) => {
          if (url) {
            const href =
              url.includes('http://') || url.includes('https://')
                ? url
                : `https://${url}`;

            return (
              <div className="link">
                <a
                  href={href}
                  rel="noopener noreferrer"
                  style={{ paddingLeft: 5 }}
                  target="_blank"
                >
                  {names[i] || url}
                </a>
              </div>
            );
            return null;
          }
        })
        .filter(x => x);

      return nodes.length > 0 ? (
        <div className="gothic vendor-links">
          Vendors
          {nodes}
        </div>
      ) : null;
    };

    return (
      <div
        className="event-show"
        style={{ padding: '7% 5%', textAlign: 'center' }}
      >
        <DeleteEventButton event={event} />
        <div className="script" style={{ fontSize: '40px' }}>
          {eventDate}
        </div>
        <div
          style={{
            marginBottom: '7.5%',
            padding: '0% 16%',
            fontFamily: 'Gothic A1',
            fontSize: '21px',
            textTransform: 'uppercase',
            fontWeight: '100',
            letterSpacing: '5px',
          }}
        >
          {title || name}
        </div>

        <div
          style={{
            marginBottom: '7.5%',
            padding: '0% 16%',
            fontFamily: 'Gothic A1',
            fontSize: '13px',
            textTransform: 'uppercase',
            letterSpacing: '1.15px',
            lineHeight: '26px',
          }}
        >
          {description}
        </div>
        {renderVendorLinks()}
        {photos && renderPhotos}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getEvents: () => dispatch(getEvents()),
});

const mapStateToProps = state => ({
  events: state.events.all,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventShow);
