import React from 'react';

export const LANDSCAPE = {
  ratio: 675 / 440,
  height: 440,
  width: 675,
  tag: 'L',
};
export const PORTRAIT = {
  ratio: 330 / 480,
  height: 480,
  width: 330,
  tag: 'P',
};

export const SQUARE = {
  ratio: 300 / 300,
  height: 300,
  width: 300,
  tag: 'S',
};

export const FORMAT_MATRIX = [
  [LANDSCAPE],
  [PORTRAIT, PORTRAIT],
  [LANDSCAPE],
  [PORTRAIT, PORTRAIT],
  [LANDSCAPE],
  [PORTRAIT, PORTRAIT],
];

export const MIN_SIZE = 330;

export function getCroppedImg(image, crop, fileName) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  if (crop.aspect == LANDSCAPE.ratio) {
    canvas.width = LANDSCAPE.width;
    canvas.height = LANDSCAPE.height;
  } else {
    canvas.width = PORTRAIT.width;
    canvas.height = PORTRAIT.height;
  }
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    canvas.width,
    canvas.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      blob => {
        if (!blob) {
          // reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');

          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({ name: fileName, blobUrl: this.fileUrl, blob });
      },
      'image/jpeg',
      0.92
    );
  });
}

export const addLineBreaks = (string, addDouble = false) => {
  return string.split('\n').map((text, index) => (
    <React.Fragment key={`${text} ${index}`}>
      {text}
      <br />
      {addDouble && <br />}
    </React.Fragment>
  ));
};
