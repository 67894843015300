/* eslint-disable sort-keys */
import React from 'react';
import Modal from 'react-modal';

import CheckBox from './checkbox';
import RangePicker from './rangePicker';

const modalStyle = {
  content: {
    fontFamily: 'Gothic A1',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    textTransform: 'uppercase',
    textAlign: 'center',
    width: 'unset',
  },
};

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: {
        1: '',
        2: '',
        3: '',
      },
      eventDate: '',
      registration: '',
      venue: '',
      venueArtisan: false,
      venueFeast: false,
      range: {
        Balloon: false,
        Full: false,
        Micro: false,
        Social: false,
      },
      guestCount: '',
      pintrestLink: '',
      howDidYouHear: '',
      message: '',
      invalidations: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isDataValid = this.isDataValid.bind(this);
  }

  handleChange(data) {
    this.setState({ ...this.state, ...data });
  }

  isDataValid() {
    let invalidations = [];

    if (this.state.name.trim() === '') invalidations.push('Name');
    if (this.state.email.trim() === '') invalidations.push('Email');
    if (this.state.eventDate.trim() === '') invalidations.push('Event Date');
    if (this.state.venue.trim() === '') invalidations.push('Venue');

    this.setState({ invalidations });

    return invalidations.length === 0;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.isDataValid()) this.props.onSubmit(this.state);
  }

  render() {
    return (
      <div className="contact-form-container">
        <div className="script">contact form</div>
        <br />
        <div className="contact-form-header gothic">
          To schedule your complimentary consultation meeting, please complete
          the following contact form
        </div>
        <br />

        <form className="contact-form gothic" onSubmit={this.handleSubmit}>
          <label>
            NAME <br />
            <input
              className="text-input name full-width"
              placeholder="  COUPLES - PLEASE LIST BOTH OF YOUR NAMES"
              type="text"
              value={this.state.name}
              onChange={event =>
                this.handleChange({ name: event.target.value })
              }
            />
          </label>
          <br />
          <label>
            EMAIL ADDRESS <br />
            <input
              className="text-input email full-width"
              type="text"
              value={this.state.email}
              onChange={event =>
                this.handleChange({ email: event.target.value })
              }
            />
          </label>
          <br />
          <label>
            PHONE <br />
            <input
              className="text-input phone"
              placeholder="(###)"
              type="text"
              value={this.state.phone[1]}
              onChange={event =>
                this.handleChange({
                  phone: { ...this.state.phone, 1: event.target.value },
                })
              }
            />
            <input
              className="text-input phone"
              placeholder="###"
              type="text"
              value={this.state.phone[2]}
              onChange={event =>
                this.handleChange({
                  phone: { ...this.state.phone, 2: event.target.value },
                })
              }
            />
            <input
              className="text-input phone"
              placeholder="####"
              type="text"
              value={this.state.phone[3]}
              onChange={event =>
                this.handleChange({
                  phone: { ...this.state.phone, 3: event.target.value },
                })
              }
            />
          </label>
          <br />
          <label>
            EVENT DATE <br />
            <input
              className="text-input event-date full-width"
              type="text"
              value={this.state.eventDate}
              onChange={event =>
                this.handleChange({ eventDate: event.target.value })
              }
            />
          </label>
          <br />
          <label>
            VENUE <br />
            <input
              className="text-input venue full-width"
              type="text"
              value={this.state.venue}
              onChange={event =>
                this.handleChange({ venue: event.target.value })
              }
            />
          </label>

          <input
            className="text-input registration full-width"
            type="text"
            value={this.state.registration}
            onChange={event =>
              this.handleChange({ registration: event.target.value })
            }
          />
          <CheckBox
            label="ARTESANO CLIENT PLEASE CHECK THIS BUTTON"
            subLabel="We will send you a basic package information after you complete the form            "
            value={this.state.venueArtisan}
            onChange={() =>
              this.handleChange({ venueArtisan: !this.state.venueArtisan })
            }
          />
          <p />
          <CheckBox
            label="FEAST YOUR EYES CLIENT PLEASE CHECK THIS BUTTON"
            subLabel="We will send you a basic package information after you complete the form            "
            value={this.state.venueFeast}
            onChange={() =>
              this.handleChange({ venueFeast: !this.state.venueFeast })
            }
          />
          <br />
          <label>
            {' '}
            <br />
            WHAT TYPE OF SERVICE ARE YOU INTERESTED IN? <br /> <p />
            <RangePicker
              value={this.state.range}
              onChange={this.handleChange}
            />
          </label>
          <br />
          <label>
            YOUR ESTIMATED GUEST COUNT <br />
            <input
              className="text-input guest-count full-width"
              type="text"
              value={this.state.guestCount}
              onChange={event =>
                this.handleChange({ guestCount: event.target.value })
              }
            />
          </label>
          <br />
          <label>
            PINTEREST LINK <br />
            <p>
              IF APPLICABLE, PLEASE INCLUDE YOUR PINTEREST LINK HERE. IT HELPS
              US TREMENDOUSLY IN BETTER UNDERSTANDING YOUR VISION
            </p>
            <input
              className="text-input pinterest-link full-width"
              type="text"
              value={this.state.pintrestLink}
              onChange={event =>
                this.handleChange({ pintrestLink: event.target.value })
              }
            />
          </label>
          <br />
          <br />
          <label>
            CONSULTATIONS ARE SCHEDULED ONLY ON TUESDAYS AND WEDNESDAYS via
            GOOGLE MEET between 11am-7pm EST
          </label>

          <br />
          <br />
          <label>
            HOW DID YOU HEAR ABOUT US? <br />
            <input
              className="text-input how-hear full-width"
              type="text"
              value={this.state.howDidYouHear}
              onChange={event =>
                this.handleChange({ howDidYouHear: event.target.value })
              }
            />
          </label>
          <br />
          <label>
            MESSAGE <br />
            <textarea
              className="text-input message full-width"
              type="textarea"
              value={this.state.message}
              onChange={event =>
                this.handleChange({ message: event.target.value })
              }
            />
          </label>
          <br />
          <input className="submit" type="submit" value="SUBMIT" />
        </form>
        <Modal
          isOpen={this.state.invalidations.length > 0}
          onRequestClose={() => this.setState({ invalidations: [] })}
          style={modalStyle}
          contentLabel="Form Validation"
        >
          <p style={{ fontWeight: 'bold' }}>
            The following fields cannot be blank:
          </p>
          {this.state.invalidations.map(i => (
            <p style={{ color: '#e9aba3', lineHeight: '2px' }}>{i}</p>
          ))}
          <button
            className="btn"
            onClick={() => this.setState({ invalidations: [] })}
          >
            OK
          </button>
        </Modal>
      </div>
    );
  }
}

export default ContactForm;
