export default async function createEvent(formData, token) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/events/create`,
      {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }
    );
    console.log('service response: createEvent', response);
    if (response.ok) return response.json();
    throw response.status;
  } catch (e) {
    console.error('service error: createEvent', e);
    return Promise.reject(e);
  }
}
