import { push } from 'connected-react-router';
import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import * as actions from '../../../actions';
import Panel from '../../../shared-components/panel';

import Form from './form/index';

/* eslint-disable sort-keys */
const panelText = {
  panelOne: [
    {
      textOne: 'what does full service mean?',
      textTwo:
        'Every event is the opportunity to create an experience for your guests from the moment they arrive. Our designers will work closely with you from consultation all the way through to executing the finishing touches on your big day.',
      textThree:
        'We can cover all the bases, from floral arrangements to linens, place settings, signage, lighting, draping, and furniture rentals - everything the eye sees is part of our design palette. Full service includes all of your floral arrangements and decor, day-of setup, and teardown by our team.',
    },
  ],
  panelTwo: [
    {
      textOne: 'investment',
      textTwo:
        'Each event is custom designed, and depends on so many factors that we do not provide pricing without an initial consultation. In general, we have a minimum order of $10,000 excluding taxes. Any suspension works will require a minimum order of $15,000 excluding taxes',
      textThree: '',
    },
  ],
  panelThree: [
    {
      textOne: 'find us',
      textTwo: '16 N 3RD STREET \n Philadelphia, PA 19106',
      textThree:
        '* PLEASE KEEP IN MIND THAT WE ONLY USE OUR OFFICE AS MEETING SPACE FOR OUR CLIENTS. ANY MAILINGS SENT TO THE ABOVE ADDRESS DO NOT GET CHECKED ON A REGULAR BASIS',
      textFour: 'BY APPOINTMENT ONLY',
    },
    {
      textOne: 'contact us',
      textTwo: 'info@papertini.com',
      textThree: '',
      textFour: 'WE ARE CLOSED MONDAYS',
    },
  ],
};
/* eslint-enable sort-keys */

const modalStyle = {
  content: {
    bottom: 'auto',
    fontFamily: 'Gothic A1',
    left: '50%',
    marginRight: '-50%',
    right: 'auto',
    textAlign: 'center',
    textTransform: 'uppercase',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'unset',
  },
};

class Contact extends Component {
  render() {
    return (
      <div className="contact-page">
        <Panel text={panelText.panelOne}>
          {/* <img src="map.png" />
          <img src="map.png" /> */}
        </Panel>

        <div className="pink-tile-row">
          <div className="pink-tile">
            <div className="star">
              <img
                alt="test"
                src="https://s3.amazonaws.com/papertini/home_screen/misc_flowers/star-02.svg"
              />
            </div>
            <div className="pink-header script">floral arrangements</div>
            <div>
              include bouquets, boutonnieres, corsages, hair pieces, ceremony
              installations, centerpieces, suspended decor, photo-booth
              backdrops, etc.
            </div>
          </div>
          <div className="pink-tile">
            <div className="star">
              <img
                alt="test"
                src="https://s3.amazonaws.com/papertini/home_screen/misc_flowers/star-02.svg"
              />
            </div>
            <div className="pink-header script">props</div>
            <div className="gothic">
              include arches, chuppahs, candle holders, table numbers, lanterns,
              balloons, paper florals, easels, decorative elements, etc.
            </div>
          </div>
          <div className="pink-tile">
            <div className="star">
              <img
                alt="test"
                src="https://s3.amazonaws.com/papertini/home_screen/misc_flowers/star-02.svg"
              />
            </div>
            <div className="pink-header script">custom signage</div>
            <div className="gothic">
              helps the flow of your event and can include welcoming guests,
              seating charts, menu boards, signature drinks, directions, etc.
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className="flower_patch">
            <img
              alt="test"
              src="https://papertini.s3.amazonaws.com/contact/flower+patch.png"
            />
          </div>
        )}
        <Panel className="pricing" text={panelText.panelTwo} />

        {/* <Panel backgroundColor="gray" text={panelText.panelThree}>
          <img src="map.png" style={{ width: '250px', height: '250px' }} />
          {!isMobile && (
            <img
              alt="test"
              className="contacts_flower02"
              src="https://papertini.s3.amazonaws.com/contact/flower02.png"
            />
          )}
        </Panel> */}

        {/* <div className="panel">
          <div className="panel-text">
            <div className="panel-font-1 script">specially for you</div>
            <div className="panel-font-2 sister-site gothic">
              <div>BOUQUET PRESERVATION INQURY,</div>
              <div>PLEASE VISIT OUR SISTER COMPANY</div>
              <div>HANA WILLOW DESIGN AT</div>
              <div>
                <a
                  href="http://www.hanawillowdesign.com"
                  style={{ color: '#F2D2C3' }}
                >
                  WWW.HANAWILLOWDESIGN.COM
                </a>
              </div>
            </div>
          </div>

          <div className="panel-image">
            <div className="panel-image-src">
              <img
                alt="test"
                className="frame01"
                src="https://papertini.s3.amazonaws.com/contact/frame01.png"
              />
              <img className="frame-photo" src="map.png" alt=""/>
            </div>
          </div>
        </div> */}

        {/* <Form onSubmit={this.props.sendContact} /> */}
        <Modal
          isOpen={!!this.props.success}
          onRequestClose={this.props.onSuccess}
          style={modalStyle}
          contentLabel="Success"
        >
          <p>Form sent! We'll be in touch shortly</p>
          <button
            style={{ backgroundColor: '#e9aba3', color: '#fff' }}
            className="btn"
            onClick={this.props.onSuccess}
          >
            OK
          </button>
        </Modal>
        <Modal
          isOpen={!!this.props.error}
          onRequestClose={this.props.clearError}
          style={modalStyle}
          contentLabel="Error"
        >
          <p>Something went wrong! Your contact form was not sent.</p>
          <button className="btn" onClick={this.props.clearError}>
            OK
          </button>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ contact }) => ({
  error: contact.error,
  isLoading: contact.isLoading,
  success: contact.success,
});

const mapDispatchToProps = dispatch => ({
  clearError: () => dispatch(actions.clearContactError()),
  navHome: () => dispatch(push('/')),
  onSuccess: () => dispatch(actions.onContactSuccess()),
  sendContact: payload => dispatch(actions.sendContact(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact);
