import { applyMiddleware, createStore, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import REDUCERS from './reducers';
import { checkSession } from './actions';

export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, REDUCERS);

const INITIAL_STATE = {
  // Any properties delcared here will override any inital states set in reducers
  // based on the ru les of the spread operator
};

const MIDDLEWARE = applyMiddleware(thunk, routerMiddleware(history));

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  connectRouter(history)(persistedReducer),
  { ...INITIAL_STATE },
  composeEnhancers(MIDDLEWARE)
);

export const persistor = persistStore(store, {}, () =>
  store.dispatch(checkSession())
);
