import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
  }

  forgotPassword = event => {
    event.preventDefault();
    const { email, forgotPassword } = this.props;

    forgotPassword({ email });
  };

  renderButton() {
    if (this.props.loading) {
      return <p>L o a d i n g . . .</p>;
    }

    return (
      <button
        className="btn"
        style={{ backgroundColor: '#e9aba3' }}
        type="submit"
      >
        Send Password Reset Email
      </button>
    );
  }

  render() {
    const {
      email,
      password,
      error,
      emailChanged,
      passwordChanged,
    } = this.props;

    return (
      <div className="login-form gothic">
        <h3>Forgot Password</h3>
        <form className="box" onSubmit={this.forgotPassword}>
          <div>
            <input
              id="email"
              className="login-email"
              type="text"
              placeholder="Email"
              onChange={e => emailChanged(e.target.value)}
              value={email}
            />
          </div>

          <div>{this.renderButton()}</div>

          <div>{error}</div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { email, password, error, loading, forgotPassword } = auth;

  return { email, password, error, loading, forgotPassword };
};

export default connect(
  mapStateToProps,
  actions
)(ForgotPassword);

ForgotPassword.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  emailChanged: PropTypes.func.isRequired,
  passwordChanged: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
};
