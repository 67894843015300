import { push } from 'connected-react-router';

import {
  CHECK_SESSION,
  EMAIL_CHANGED,
  PASSWORD_CHANGED,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGIN_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER,
  SHOW_FORGOT_PASSWORD,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
} from './types';

const jwtDecode = require('jwt-decode');

export const emailChanged = text => ({
  type: EMAIL_CHANGED,
  payload: text,
});

export const passwordChanged = text => ({
  type: PASSWORD_CHANGED,
  payload: text,
});

export const showForgotPassword = bool => ({
  type: SHOW_FORGOT_PASSWORD,
  payload: bool,
});

export const loginUser = values => dispatch => {
  dispatch({ type: LOGIN_USER });

  fetch(`${process.env.REACT_APP_API_URL}/api/users/login`, {
    method: 'POST',
    body: JSON.stringify(values),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json())
    .then(body => {
      loginUserSuccess(dispatch, body.token);
    })
    .then(() => dispatch(push('/employee')))
    .catch(() => loginUserFail(dispatch));
};

const loginUserFail = dispatch => {
  dispatch({ type: LOGIN_USER_FAIL });
};

const loginUserSuccess = (dispatch, token) => {
  dispatch({
    type: LOGIN_USER_SUCCESS,
    token,
    user: jwtDecode(token),
  });
};

export const logoutUser = () => dispatch => {
  dispatch({ type: LOGOUT_USER });
  dispatch({ type: LOGOUT_USER_SUCCESS });
  dispatch(push('/'));
  // endpoint doesn't exist yet
  // fetch('http://localhost:3001/api/users/logout', {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  // })
  //   .then(res => res.json())
  //   .then(() => {
  //     dispatch({ type: LOGOUT_USER_SUCCESS });
  //   })
  //   .then(() => dispatch(push('/')));
  // .catch(() => dispatch({ type: LOGOUT_USER_FAIL }));
};

export const forgotPassword = values => dispatch => {
  dispatch({ type: FORGOT_PASSWORD });

  fetch(`${process.env.REACT_APP_API_URL}/api/users/forgot`, {
    method: 'POST',
    body: JSON.stringify(values),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json())
    .then(body => {
      if (body.success) {
        dispatch({ type: FORGOT_PASSWORD_SUCCESS });
      } else if (!body.success) {
        dispatch({ type: FORGOT_PASSWORD_FAIL, payload: body.error });
      } else if (body.error) {
        dispatch({ type: FORGOT_PASSWORD_FAIL, payload: body.error });
      }
    })
    .catch(body =>
      dispatch({ type: FORGOT_PASSWORD_FAIL, payload: body.error })
    );
};

export const checkSession = () => (dispatch, getState) => {
  dispatch({ type: CHECK_SESSION.PENDING });

  fetch(`${process.env.REACT_APP_API_URL}/api/users/current`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getState().auth.token,
    },
  })
    .then(res => {
      if (!res.ok) throw 'err';
      dispatch({ type: CHECK_SESSION.SUCCESS });
    })
    .catch(() => {
      dispatch({ type: CHECK_SESSION.FAIL });
      if (getState().router.location.pathname === 'employee') {
        dispatch(push('/'));
      }
    });
};
