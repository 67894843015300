import React from 'react';

import { PhotoCropper } from '../../../shared-components';
import { SQUARE } from '../../../utilities';

function PhotoSection({ handleCropComplete, photo, removePhoto }) {
  const { blobUrl, url } = photo || {};
  const source = blobUrl || url;

  const Photo = () => (
    <div className="image" key={source}>
      <span
        className="close-button"
        style={{
          position: 'absolute',
          display: 'inline',
          backgroundColor: '#ffffff99',
        }}
        onClick={removePhoto}
      >
        <i className="far fa-window-close fa-2x" />
      </span>
      <img alt="" src={source} />
    </div>
  );

  function renderFormat() {
    return photo ? (
      <Photo />
    ) : (
      <PhotoCropper onClickDone={handleCropComplete} orientation={SQUARE} />
    );
  }

  return <div className="photo-section">{renderFormat()}</div>;
}

export default PhotoSection;
