import { push } from 'connected-react-router';

import {
  SEND_CONTACT_CLEAR_ERROR,
  SEND_CONTACT_CLEAR_SUCCESS,
  SEND_CONTACT_SUCCESS,
  SEND_CONTACT_FAIL,
  SEND_CONTACT,
} from './types';

export const sendContact = values => dispatch => {
  dispatch({ type: SEND_CONTACT });

  fetch(`${process.env.REACT_APP_API_URL}/api/contact`, {
    method: 'POST',
    body: JSON.stringify(values),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json())
    .then(body =>
      body.success ? dispatch({ type: SEND_CONTACT_SUCCESS }) : Promise.reject()
    )
    .catch(() => dispatch({ type: SEND_CONTACT_FAIL }));
};

export const clearContactError = () => dispatch => {
  dispatch({ type: SEND_CONTACT_CLEAR_ERROR });
};

export const onContactSuccess = () => dispatch => {
  dispatch({ type: SEND_CONTACT_CLEAR_SUCCESS });
  dispatch(push('/'));
};
