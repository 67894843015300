import React, { Component } from 'react';
import { NavLink, Link, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './actions';
import * as Page from './pages';
import { Footer } from './shared-components';
import NavBar from './navbar';
import ForgotPassword from './pages/employee/login/ForgotPassword';
import NewPassword from './pages/employee/login/NewPassword';

class App extends Component {
  componentWillReceiveProps() {
    window.scroll(0, 0);
  }

  componentDidMount() {
    this.props.getAllUsers();
    this.props.getSpecial();
  }

  render() {
    const { currentUser, logoutUser } = this.props;

    return (
      <div className="app container-fluid">
        <NavBar currentUser={currentUser} logoutUser={logoutUser} />

        {/* Route components are rendered if the path prop matches the current URL */}
        <Route exact path="/" component={Page.Home} />
        <Route exact path="/portfolio" component={Page.Portfolio} />
        <Route path="/portfolio/:id" component={Page.EventShow} />
        <Route path="/contact" component={Page.Contact} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/newpassword/:token" component={NewPassword} />
        {currentUser ? (
          <Route path="/employee" component={Page.Portal} />
        ) : (
          <Route path="/login" component={Page.Login} />
        )}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth, router }) => {
  const { user } = auth;

  return {
    currentUser: user,
    path: router.location.pathname,
  };
};

export default connect(
  mapStateToProps,
  actions
)(App);
