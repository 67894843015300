import React from 'react';
import { isMobile } from 'react-device-detect';

import * as TEXT from './text';

export default function Footer() {
  return (
    <div className="footer">
      {!isMobile && (
        <img
          alt="test"
          className="footer-lattice"
          src="https://s3.amazonaws.com/papertini/home_screen/05_footer/FOOTER+LATTICE.png"
        />
      )}
      <div className="top-row">
        <div className="left">
          <div className="address">
            <div>{TEXT.ADDRESS1}</div>
            <div>{TEXT.ADDRESS2}</div>
            <div>{TEXT.ADDRESS3}</div>
          </div>
          <br />
        </div>
        <div className="center">
          <img
            alt="header"
            className="header-image"
            src="https://s3.amazonaws.com/papertini/home_screen/01_header/logo+only.svg"
          />
          <div>{TEXT.EMAIL}</div>
        </div>
        <div className="right">
          <div className="phone-number">{TEXT.PHONE_NUMBER}</div>
          <div className="notice">
            <div>{TEXT.NOTICE1}</div>
            <div>{TEXT.NOTICE2}</div>
          </div>
        </div>
      </div>

      <div className="copyright" style={{ marginTop: '10px' }}>
        {TEXT.COPYRIGHT}
      </div>
    </div>
  );
}
