import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { addLineBreaks } from '../../../utilities';

class SpecialForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      header: '',
      body: '',
      error: '',
    };
  }

  onSubmit = e => {
    e.preventDefault();
    const { header, body } = this.state;
    const { createSpecial } = this.props;

    if (!body || !header) {
      this.setState({ error: 'All fields required' });
    } else {
      createSpecial({ header, body });
      this.setState({ error: '' });
    }
  };

  onTextChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  renderButton = () => {
    if (this.props.loading) {
      return <p>L o a d i n g . . .</p>;
    }

    return (
      <button className="btn" type="submit">
        Create Special
      </button>
    );
  };

  renderErrors = () => {
    return Object.values(this.props.errors).map(error => {
      return (
        <div className="error" style={{ color: 'red' }}>
          {error}
        </div>
      );
    });
  };

  render() {
    const { header, body, error } = this.state;

    const {
      special: { currentDeal },
    } = this.props;

    return (
      <div className="row">
        <div className="col">
          <h2>Current Special:</h2>
          <h5>Title:</h5>
          <p>{currentDeal && currentDeal.header}</p>
          <h5>Body:</h5>
          <p>{currentDeal && addLineBreaks(currentDeal.body)}</p>
        </div>
        <form className="col" onSubmit={this.onSubmit}>
          <h2>Create New Special:</h2>
          <div>Note: the new special will replace the current special</div>
          <label>Title:</label>
          <br />
          <textarea value={header} onChange={this.onTextChange} name="header" />
          <br />
          <label>Body:</label>
          <br />
          <textarea value={body} onChange={this.onTextChange} name="body" />
          <br />
          {error && <div style={{ color: 'red' }}>{error}</div>}
          <button type="submit">Create Special</button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ special }) => {
  return { special };
};

export default connect(
  mapStateToProps,
  actions
)(SpecialForm);
