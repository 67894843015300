import React from 'react';

function HeaderImage({ onLoad }) {
  return (
    <div className="header-image">
      <img
        alt="header"
        className="header-image"
        src="https://papertini.s3.amazonaws.com/home_screen/01_header/+01+PapertiniSite.jpg"
        onLoad={onLoad}
      />
      <div className="header-image-inset">
        <img
          alt="header"
          className="header-image"
          style={{ backgroundColor: '#fff7' }}
          src="https://s3.amazonaws.com/papertini/home_screen/01_header/logo+only.svg"
        />
      </div>
    </div>
  );
}

export default HeaderImage;
